//var href = (window.location.href.indexOf("/search") !== -1)?window.location.href.slice(0, window.location.href.indexOf("search")):require.toUrl(''); 
/*require.config({
  
   //urlArgs: 'v=3',
  paths: {
        'piwik': '/analytics/piwik',
		'jquery': 'lib/jquery/jquery-1.8.3.min',
		'underscore':'lib/underscore',
		'text': 'lib/text',
		'backbone':'lib/backbone',
		'bootstrap': 'lib/bootstrap/js/bootstrap',
		'moment': 'lib/moment/moment.min',
		'hammer':'lib/hammer/hammer.min',
		'switch':'lib/switch/bootstrap-switch',
		'jqueryhammer':'lib/hammer/jquery.hammer.min' ,
		'requirejs':'lib/require',
		'bootstrap-datepicker':'lib/datepicker/js/bootstrap-datepicker'
  } ,

 shim: {
	backbone: {
	    deps: ['jquery', 'underscore'],
	    exports: 'Backbone'
	},
	underscore: {
	    exports: '_'
	}
  },
  waitSeconds: 0
});

// 2 configs needs for the optimizer do not remove
require.config({
	baseUrl: href,
});
*/

require( ["jquery", "backbone", "mainView","tracking"], function($, Backbone,MainView,tracking ) {
	    $(function() {
           mainView = new MainView();
           mainView.setElement($('body')).renderView();

           $(document).on ('keypress' ,function(e){
           		if(e.which == 13) {
           			$(e.target).click();
           		}
           })


		});
	  }
);
